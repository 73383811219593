<template>
  <div class="large-page">
    <!-- Show the filters here -->
    <div class="d-flex justify-space-between">
      <!-- The link back to the campaign plan -->
      <v-btn
        text
        depressed
        color="primary"
        :to="{ name: 'CampaignPlanningIndex' }"
      >
        <v-icon left>view_list</v-icon>
        View Plans
      </v-btn>

      <div class="d-flex align-center">
        <!-- The filter for cost selector -->
        <filter-cost
          class="mr-3"
          :platform="selectedPlatform"
        />

        <!-- the select options for different platforms -->
        <platform-selector
          :value="selectedPlatform"
          @input="handlePlatformChange"
          class="platform-selector ml-auto"
        />
      </div>
    </div>

    <!-- Show filter options and applied filters here -->
    <v-card
      class="pa-4 box-shadow-md mt-4"
      outlined
    >
      <v-card-text>
        <!-- show all possible options for search/filter -->
        <filter-options />

        <v-divider class="mb-3 mt-4"></v-divider>

        <!-- view the chips for selected/applied filters -->
        <applied-filters
          :filters="appliedFilters"
          @remove="handleRemove"
        />
      </v-card-text>
    </v-card>

    <template v-if="shouldShowResults && apiData && apiData.items">
      <results-table
        :results="apiData.items"
        @addToPlan="handleAddToPlan"
      />

      <div
        id="intersect-detector"
        ref="intersectDetector"
        v-intersect="handleIntersect"
      ></div>
    </template>
  </div>
</template>

<script>
import { getValue } from "@/helpers/store.js"

const PlatformSelector = () => import(/* webpackChunkName: "platform-selector" */ "@/blocks/common/selectors/PlatformSelector.vue")
const FilterOptions = () => import(/* webpackChunkName: "filter-options-campaign-planning" */ "@/components/campaign-planning/FilterOptions.vue")
const ResultsTable = () => import(/* webpackChunkName: "results-table-campaign-planning" */ "@/components/campaign-planning/ResultsTable.vue")
const AppliedFilters = () => import(/* webpackChunkName: "applied-filters" */ "@/components/common/AppliedFilters.vue")
const FilterCost = () => import(/* webpackChunkName: "filter-cost-campaign-planning" */ "@/blocks/campaign-planning/FilterCost.vue")

export default {
  name: "CampaignPlanningSearch",

  components: {
    PlatformSelector,
    FilterOptions,
    AppliedFilters,
    ResultsTable,
    FilterCost
  },

  data: () => ({
    requestCompletedAt: null,
    currentPage: 1,
    selectedPlatform: "instagram",
    shouldShowResults: false,

    apiData: null,
    isLoading: false,

    // To make sure we can cancel requests within a time period
    _timerId: null,
    // When the first request was done
    requestCompletedAt: null,
  }),

  computed: {
    // Get the applied filters from vuex store
    appliedFilters() {
      return this.$store.getters[`campaignPlanning/filters`]
    },
  },

  watch: {
    appliedFilters() {
      this.handleFilterChange()
    }
  },

  methods: {
    /**
     * Reset results when the platform is switched
     *
     * @returns {void}
     */
    handlePlatformChange(value) {
      this.selectedPlatform = value
      this.shouldShowResults = false
      this.currentPage = 1
      this.apiData = null

      // And then run the search again
      this.doSearch(true)
    },

    /**
     * Whenever a filter has been added, removed or updated, reset the state and fetch data again
     *
     * @returns {void}
     */
    handleFilterChange() {
      // Reset the pagination counter
      this.currentPage = 1

      // Make the network request
      this.doSearch(true)
    },

    /**
     * Remove applied filter when clicked the icon
     *
     * @param {Object} data
     * @returns {void}
     */
    handleRemove(data) {
      this.$store.dispatch(`campaignPlanning/removeFilter`, data.id)
    },

    /**
     * If the user has scrolled past the current results, try fetching more
     *
     * @param {Object} entries
     * @param {Object} observer
     * @param {Boolean} isIntersecting
     */
    handleIntersect(entries, observer, isIntersecting) {
      // if the function is called under 2 seconds after it first loaded content, don't continue
      if ((Date.now() - this.requestCompletedAt) <= 1000) return false

      // If we haven't fetched first data yet, don't continue
      if (this.apiData === null) return false

      // stop execution if user didn't scroll down
      if (!isIntersecting) return false

      // If a request is being made, don't continue
      if (this.isLoading) return false

      // If there's no data right now, don't ask for next page
      if (!this.apiData.items.length) return false

      // If there's no data for the next page, don't continue
      if (this.apiData.total <= (this.apiData.currentPage * this.apiData.perPage)) return false

      // Otherwise
      this.currentPage++
      this.doSearch()
    },

    /**
     * Make the network request to fetch the results
     *
     * @param {Boolean} updateTimestamp - Whether or not to update the first response timestamp, done for 1st page of requests
     * @returns {void}
     */
    doSearch(updateTimestamp = false) {
      // Cancel pending call
      if (this._timerId) {
        clearTimeout(this._timerId)
      }

      // Delay new call 500ms
      this._timerId = setTimeout(async () => {
        this.isLoading = true

        const loaderId = Symbol()
        this.$store.dispatch("loaders/add", loaderId)

        try {
          // Set the default API data
          if (this.apiData === null) {
            this.apiData = {
              total: 10,
              perPage: 15,
              currentPage: 1,
              items: []
            }
          }

          // Compute the request object
          const getter = this.$store.getters["campaignPlanning/findFilterByType"]

          const requestBody = {
            location: getValue({ type: "location", path: "name", defaultValue: null }, getter),
            language: getValue({ type: "language", path: "name", defaultValue: null }, getter),
            gender: getValue({ type: "gender", defaultValue: null }, getter),
            category: getValue({ type: "category", defaultValue: null }, getter),
            engagement_rate: getValue({ type: "engagements", path: "rate", defaultValue: null }, getter),
            followers_from: getValue({ type: "followers", path: "from", defaultValue: null }, getter),
            followers_upto: getValue({ type: "followers", path: "to", defaultValue: null }, getter),
            interests: this.$store.getters["campaignPlanning/findFiltersByType"]("interest").map((item) => item.data.inputs.name),
            costs: this.$store.getters["campaignPlanning/findFiltersByType"]("cost").map((item) => ({
              for: item.data.inputs.for,
              minimum: Number(item.data.inputs.from),
              maximum: item.data.inputs.to === Infinity.toString() ? null : Number(item.data.inputs.to)
            }))
          }

          // Make the network request
          const response = await axios({
            url: `/api/local/influencers/${this.selectedPlatform}?page=${this.currentPage}`,
            method: "POST",
            data: requestBody
          })

          // Store the apiData locally
          this.apiData.total = response.data.total
          this.apiData.perPage = response.data.per_page
          this.apiData.currentPage = response.data.current_page

          // Also update the local pagination value
          this.currentPage = response.data.current_page

          // If the current_page is 1, overwrite values
          if (response.data.current_page === 1) {
            this.apiData.items = []
          }

          // Now merge the items
          this.apiData.items = this.apiData.items.concat(response.data.data.map((item, index) => ({
            ...item,
            isLoading: false,
            added: false
          })))

          // Show the table now
          this.shouldShowResults = true

          if (updateTimestamp) {
            this.requestCompletedAt = Date.now()
          }
        } catch (error) {
          logger({ type: "Influencers Search Error", error })

          this.$store.dispatch("toasts/add", { text: "An error occurred!" })
        } finally {
          this.isLoading = false
          this.$store.dispatch("loaders/remove", loaderId)
        }
      }, 500)
    },

    /**
     * Called from child component
     *
     * @param {Object} _item
     */
    async handleAddToPlan(_item, campaignPlanId = null) {
      const item = this.apiData.items.find((search) => search.id === _item.id)

      item.isLoading = true

      try {
        await axios({
          url: `/api/campaign-plans/${campaignPlanId || this.$route.params.campaignPlanId}/items`,
          method: "PUT",
          data: {
            items: [
              {
                name: item.name,
                preview: item.preview,
                username: item.username,
                platform: item.platform,
                followers: item.followers,
                engagement_rate: item.engagement_rate,

                locations: item.locations,
                languages: item.languages,
                interests: item.interests,

                email: item.email,
                phone: item.phone,

                costs: {
                  cost_per_static: item.cost_per_static,
                  cost_per_video: item.cost_per_video,
                  cost_per_reel: item.cost_per_reel,
                  cost_per_story: item.cost_per_story,
                  cost_per_integrated_video: item.cost_per_integrated_video,
                  cost_per_dedicated_video: item.cost_per_dedicated_video,
                  cost_per_short: item.cost_per_short
                }
              }
            ]
          }
        })

        // Make this row disabled
        item.added = true

        // Show a message
        this.$store.dispatch("toasts/add", { text: "Added to the campaign plan" })
      } catch (error) {
        logger({ type: "Add Item to Plan Error", error })

        this.$store.dispatch("toasts/add", { text: "An error occurred!" })
      } finally {
        item.isLoading = false
      }
    }
  },

  mounted() {
    // When ready, fetch data
    this.doSearch(true)
  },

  beforeDestroy() {
    // Clear the applied filters before we go away
    this.$store.dispatch("campaignPlanning/resetFilters")
  }
}
</script>
